import { effect, inject, Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';
import { CameraPermissionStatus } from '@core/enums/CameraPermissionStatus';
import { getDeviceAspectRatio } from '@core/utils/upload-utils/upload-webcam-utils';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class WebcamService {
  capture = new BehaviorSubject<((webcamImage: WebcamImage) => void) | undefined>(undefined);
  camData = new BehaviorSubject<MediaStream | null>(null);
  permissionStatus = signal<CameraPermissionStatus>(CameraPermissionStatus.NOT_ALLOWED);
  file = new BehaviorSubject<File | null>(null);
  camResult = this.camData.asObservable();
  isLandscape = signal<boolean>(false);
  loading = false;
  document = inject(DOCUMENT);
  constructor() {
    this.camResult.subscribe((value) => {
      this.loading = true;
      if (value) {
        setTimeout(() => {
          this.setVideoAndCanvas();
        }, 200);
      }
    });
    effect(() => {
      if (this.isLandscape() !== null || this.isLandscape() !== undefined) {
        this.setVideoAndCanvas();
      }
    });
  }
  checkPermission() {
    const aspectRatio = getDeviceAspectRatio();
    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { ideal: aspectRatio.height },
          height: { ideal: aspectRatio.width },
          aspectRatio: 16 / 9,
        },
      })
      .then((response) => {
        this.permissionStatus.set(CameraPermissionStatus.ALLOWED);
        this.camData.next(response);
      })
      .catch((error) => {
        this.permissionStatus.set(CameraPermissionStatus.NOT_ALLOWED);
        console.error(error);
      });
  }

  setVideoAndCanvas() {
    const aspectRatio = getDeviceAspectRatio();
    const video = this.document.getElementsByTagName('VIDEO')[0];
    if (video) {
      (video as HTMLVideoElement).width = aspectRatio.width;
      (video as HTMLVideoElement).height = aspectRatio.height;
      (video as HTMLVideoElement).style.aspectRatio = this.isLandscape() ? `16 / 9` : `9 / 16`;
    }
    const canvas = this.document.getElementsByTagName('CANVAS')[0];
    if (canvas) {
      (canvas as HTMLCanvasElement).width = aspectRatio.width;
      (canvas as HTMLCanvasElement).height = aspectRatio.height;
      (canvas as HTMLCanvasElement).style.aspectRatio = this.isLandscape() ? `16 / 9` : `9 / 16`;
    }
    this.loading = false;
  }
}
